import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Camera, Loader2, AlertCircle, CheckCircle2, Clock, MapPin, Calendar } from 'lucide-react';

const API_URL = 'https://tarvelbuddychatbot.lm.r.appspot.com/api';

// Form field configuration for easy maintenance and updates
const FORM_CONFIG = {
  purposeOptions: [
    'New Product Demo',
    'Maintenance Visit',
    'Customer Training',
    'Sales Follow-up',
    'Issue Resolution',
    'Contract Negotiation',
    'Product Installation',
    'Regular Check-in'
  ],
  statusOptions: [
    'COMPLETED',
    'FOLLOW REQUIRED',
    'CANCELLED',
    'IN_PROGRESS',
    'RESCHEDULED'
  ],
  priorityOptions: [
    'HIGH',
    'MEDIUM',
    'LOW'
  ],
  durationOptions: [
    { value: '15', label: '15 minutes' },
    { value: '30', label: '30 minutes' },
    { value: '45', label: '45 minutes' },
    { value: '60', label: '1 hour' },
    { value: '90', label: '1.5 hours' },
    { value: '120', label: '2 hours' },
    { value: '180', label: '3 hours' },
    { value: '240', label: '4 hours' }
  ]
};

const INITIAL_FORM_STATE = {
  name: '',
  phone: '',
  shopAddress: '',
  remarks: '',
  photo: null,
  location: null,
  purposeOfVisit: '',
  status: '',
  visitStartTime: new Date().toISOString(),
  visitDuration: '30',
  priority: 'MEDIUM',
  requirements: '',
  outcome: '',
  followUpNeeded: '',
  followUpDate: '',
  productsDiscussed: '',
  nextSteps: '',
  budget: '',
  decisionMaker: '',
  customerFeedback: '',
  meetingNotes: '',
  painPoints: '',
  competitors: '',
  potentialRevenue: '',
  probability: '50'
};

const AddDetails = ({ currentUser, onSuccess }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [stream, setStream] = useState(null);
  const [photoBlob, setPhotoBlob] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [locationError, setLocationError] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  
  const videoRef = useRef(null);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);

  // Input handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear validation error for this field
    if (validationErrors[name]) {
      setValidationErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
    setError(null);
  };

  // File handling
  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ 
        video: { facingMode: 'user' }
      });
      
      setStream(mediaStream);
      setShowCamera(true);
      
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream;
          videoRef.current.play().catch(err => {
            console.error('Error playing video:', err);
            setError('Failed to start video stream');
          });
        }
      }, 100);
    } catch (error) {
      console.error('Error accessing camera:', error);
      setError('Failed to access camera. Please check permissions.');
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setStream(null);
      setShowCamera(false);
    }
  };

  const capturePhoto = async () => {
    if (!videoRef.current) return;

    try {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      
      if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
        ctx.drawImage(videoRef.current, 0, 0);
        
        canvas.toBlob((blob) => {
          setPhotoBlob(blob);
          const photoUrl = URL.createObjectURL(blob);
          setFormData(prev => ({
            ...prev,
            photo: photoUrl
          }));
        }, 'image/jpeg', 0.8);
        
        stopCamera();
      } else {
        throw new Error('Video not ready');
      }
    } catch (error) {
      console.error('Error capturing photo:', error);
      setError('Failed to capture photo. Please try again.');
    }
  };

  // Location handling
  const getLocation = async () => {
    setIsLocationLoading(true);
    setLocationError(null);
    
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      
      const { latitude, longitude } = position.coords;
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      const data = await response.json();
      
      return {
        address: data.display_name,
        coordinates: { latitude, longitude }
      };
    } catch (error) {
      console.error('Error getting location:', error);
      setLocationError('Failed to get location. Please check permissions.');
      throw new Error('Failed to get location');
    } finally {
      setIsLocationLoading(false);
    }
  };

  // Validation
  const validateForm = () => {
    const errors = {};
    
    // Required fields
    if (!formData.name?.trim()) {
      errors.name = 'Name is required';
    } else if (formData.name.length < 2) {
      errors.name = 'Name must be at least 2 characters';
    }

    if (!formData.phone?.trim()) {
      errors.phone = 'Phone number is required';
    } else if (!/^[0-9+\-\s()]{8,20}$/.test(formData.phone)) {
      errors.phone = 'Invalid phone number format';
    }

    if (!formData.shopAddress?.trim()) {
      errors.shopAddress = 'Shop address is required';
    } else if (formData.shopAddress.length < 5) {
      errors.shopAddress = 'Address must be at least 5 characters';
    }

    if (!formData.purposeOfVisit) {
      errors.purposeOfVisit = 'Purpose of visit is required';
    }

    if (!formData.status) {
      errors.status = 'Status is required';
    }

    // Conditional validation
    if (formData.status === 'FOLLOW REQUIRED' && !formData.followUpDate) {
      errors.followUpDate = 'Follow-up date is required for this status';
    }

    if (formData.budget && !/^\d+$/.test(formData.budget)) {
      errors.budget = 'Budget must be a number';
    }

    if (formData.potentialRevenue && !/^\d+$/.test(formData.potentialRevenue)) {
      errors.potentialRevenue = 'Potential revenue must be a number';
    }

    if (formData.probability) {
      const prob = parseInt(formData.probability);
      if (isNaN(prob) || prob < 0 || prob > 100) {
        errors.probability = 'Probability must be between 0 and 100';
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setError('Please correct the errors in the form');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    setShowConfirmDialog(true);
  };

  const submitForm = async () => {
    setIsSubmitting(true);
    setError(null);
    setShowConfirmDialog(false);
    
    try {
      const location = await getLocation();
      
      const formDataToSend = new FormData();
      
      // Append all form fields
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== 'photo' && value !== null && value !== undefined && value !== '') {
          formDataToSend.append(key, value.toString().trim());
        }
      });
      
      formDataToSend.append('location', JSON.stringify(location));
      formDataToSend.append('createdBy', currentUser.email);
      
      if (photoBlob) {
        formDataToSend.append('photo', photoBlob, 'photo.jpg');
      }

      const response = await fetch(`${API_URL}/visits`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add visit details');
      }

      const result = await response.json();

      setFormData(INITIAL_FORM_STATE);
      setPhotoBlob(null);
      setSuccess(true);
      
      if (onSuccess) {
        onSuccess(result.data);
      }

      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error adding visit details:', error);
      setError(error.message || 'Failed to add visit details. Please try again.');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      {/* Messages */}
      <div className="mb-6">
        {success && (
          <div className="p-4 rounded-lg bg-green-50 border border-green-200 flex items-center space-x-2">
            <CheckCircle2 className="h-5 w-5 text-green-600" />
            <p className="text-green-700">Visit details added successfully!</p>
          </div>
        )}

        {error && (
          <div className="p-4 rounded-lg bg-red-50 border border-red-200 flex items-center space-x-2">
            <AlertCircle className="h-5 w-5 text-red-600" />
            <p className="text-red-700">{error}</p>
          </div>
        )}
      </div>

      {/* Main Form */}
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Information */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">Basic Information</h2>
          </div>
          <div className="p-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Customer Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${validationErrors.name ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Enter customer name"
                />
                {validationErrors.name && (
                  <p className="text-sm text-red-500">{validationErrors.name}</p>
                )}
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${validationErrors.phone ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Enter phone number"
                />
                {validationErrors.phone && (
                  <p className="text-sm text-red-500">{validationErrors.phone}</p>
                )}
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Shop Address <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="shopAddress"
                value={formData.shopAddress}
                onChange={handleInputChange}
                className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                  ${validationErrors.shopAddress ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Enter complete shop address"
              />
              {validationErrors.shopAddress && (
                <p className="text-sm text-red-500">{validationErrors.shopAddress}</p>
              )}
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Decision Maker
              </label>
              <input
                type="text"
                name="decisionMaker"
                value={formData.decisionMaker}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Name and designation of decision maker"
              />
            </div>
          </div>
        </div>

        {/* Visit Details */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">Visit Details</h2>
          </div><div className="p-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Purpose of Visit <span className="text-red-500">*</span>
                </label>
                <select
                  name="purposeOfVisit"
                  value={formData.purposeOfVisit}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${validationErrors.purposeOfVisit ? 'border-red-500' : 'border-gray-300'}`}
                >
                  <option value="">Select Purpose</option>
                  {FORM_CONFIG.purposeOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                {validationErrors.purposeOfVisit && (
                  <p className="text-sm text-red-500">{validationErrors.purposeOfVisit}</p>
                )}
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Status <span className="text-red-500">*</span>
                </label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${validationErrors.status ? 'border-red-500' : 'border-gray-300'}`}
                >
                  <option value="">Select Status</option>
                  {FORM_CONFIG.statusOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                {validationErrors.status && (
                  <p className="text-sm text-red-500">{validationErrors.status}</p>
                )}
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Priority</label>
                <select
                  name="priority"
                  value={formData.priority}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {FORM_CONFIG.priorityOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Visit Duration</label>
                <select
                  name="visitDuration"
                  value={formData.visitDuration}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {FORM_CONFIG.durationOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Products Discussed</label>
                <input
                  type="text"
                  name="productsDiscussed"
                  value={formData.productsDiscussed}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="List of products discussed"
                />
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Requirements</label>
              <textarea
                name="requirements"
                value={formData.requirements}
                onChange={handleInputChange}
                rows="3"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Customer requirements and specifications"
              />
            </div>
            
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Pain Points</label>
              <textarea
                name="painPoints"
                value={formData.painPoints}
                onChange={handleInputChange}
                rows="2"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Customer's current challenges and pain points"
              />
            </div>
          </div>
        </div>

        {/* Meeting Outcomes */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">Meeting Outcomes</h2>
          </div>
          <div className="p-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Budget</label>
                <input
                  type="number"
                  name="budget"
                  value={formData.budget}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${validationErrors.budget ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Customer's budget"
                />
                {validationErrors.budget && (
                  <p className="text-sm text-red-500">{validationErrors.budget}</p>
                )}
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Potential Revenue</label>
                <input
                  type="number"
                  name="potentialRevenue"
                  value={formData.potentialRevenue}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${validationErrors.potentialRevenue ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Expected revenue"
                />
                {validationErrors.potentialRevenue && (
                  <p className="text-sm text-red-500">{validationErrors.potentialRevenue}</p>
                )}
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Meeting Notes</label>
              <textarea
                name="meetingNotes"
                value={formData.meetingNotes}
                onChange={handleInputChange}
                rows="3"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Important points discussed during the meeting"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Outcome</label>
              <textarea
                name="outcome"
                value={formData.outcome}
                onChange={handleInputChange}
                rows="3"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Meeting outcomes and decisions made"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Next Steps</label>
              <textarea
                name="nextSteps"
                value={formData.nextSteps}
                onChange={handleInputChange}
                rows="2"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Actions to be taken"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Competitors</label>
                <input
                  type="text"
                  name="competitors"
                  value={formData.competitors}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Competing products/vendors"
                />
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Success Probability (%)</label>
                <input
                  type="number"
                  name="probability"
                  value={formData.probability}
                  onChange={handleInputChange}
                  min="0"
                  max="100"
                  className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${validationErrors.probability ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Chance of conversion"
                />
                {validationErrors.probability && (
                  <p className="text-sm text-red-500">{validationErrors.probability}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Follow-up Section */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">Follow-up Details</h2>
          </div>
          <div className="p-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Follow-up Date
                  {formData.status === 'FOLLOW REQUIRED' && <span className="text-red-500">*</span>}
                </label>
                <input
                  type="date"
                  name="followUpDate"
                  value={formData.followUpDate}
                  onChange={handleInputChange}
                  min={new Date().toISOString().split('T')[0]}
                  className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${validationErrors.followUpDate ? 'border-red-500' : 'border-gray-300'}`}
                />
                {validationErrors.followUpDate && (
                  <p className="text-sm text-red-500">{validationErrors.followUpDate}</p>
                )}
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Follow-up Notes</label>
                <textarea
                  name="followUpNeeded"
                  value={formData.followUpNeeded}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Details about required follow-up"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Photo Section */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">Photo</h2>
          </div>
          <div className="p-6">
            <div className="space-y-4">
              {showCamera ? (
                <div className="space-y-4">
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted
                    className="w-full max-w-md mx-auto rounded-lg border"
                  />
                  <div className="flex justify-center space-x-4">
                    <button
                      type="button"
                      onClick={capturePhoto}
                      className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Capture Photo
                    </button>
                    <button
                      type="button"
                      onClick={stopCamera}
                      className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center space-y-4">
                  <button
                    type="button"
                    onClick={startCamera}
                    disabled={isSubmitting}
                    className="flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400"
                  >
                    <Camera className="h-5 w-5" />
                    <span>Take Photo</span>
                  </button>
                  {formData.photo && (
                    <img 
                      src={formData.photo} 
                      alt="Captured" 
                      className="w-48 h-48 object-cover rounded-lg border"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400 flex items-center space-x-2"
          >
           {isSubmitting ? (
              <>
                <Loader2 className="h-5 w-5 animate-spin" />
                <span>Submitting...</span>
              </>
            ) : (
              <>
                <CheckCircle2 className="h-5 w-5" />
                <span>Submit Visit Details</span>
              </>
            )}
          </button>
        </div>

        {/* Confirmation Dialog */}
        {showConfirmDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
              <h3 className="text-lg font-semibold mb-4">Confirm Submission</h3>
              <p className="text-gray-600 mb-6">
                Are you sure you want to submit these visit details? Please verify all information is correct.
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => setShowConfirmDialog(false)}
                  className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={submitForm}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Confirm Submit
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Location Loading Overlay */}
        {isLocationLoading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 flex items-center space-x-4">
              <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
              <p className="text-gray-700">Getting location...</p>
            </div>
          </div>
        )}
      </form>

      {/* Quick Preview Panel */}
      {Object.keys(formData).some(key => formData[key]) && (
        <div className="fixed bottom-4 right-4">
          <div className="bg-white rounded-lg shadow-lg p-4 max-w-sm">
            <h3 className="text-sm font-semibold text-gray-700 mb-2">Quick Summary</h3>
            <div className="text-xs text-gray-600">
              <p>Customer: {formData.name}</p>
              <p>Purpose: {formData.purposeOfVisit}</p>
              <p>Status: {formData.status}</p>
              {formData.followUpDate && (
                <p>Follow-up: {new Date(formData.followUpDate).toLocaleDateString()}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Prop Types validation
AddDetails.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  onSuccess: PropTypes.func
};

export default AddDetails;