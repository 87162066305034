// src/firebase.js
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyB-jshv40n1VUonuG_vvq_gxzg-CoyLWyI",
  authDomain: "bullsoi.firebaseapp.com",
  projectId: "bullsoi",
  storageBucket: "bullsoi.appspot.com",
  messagingSenderId: "599803367291",
  appId: "1:599803367291:web:d999c4695e757219bd37de",
  measurementId: "G-64Q9N6STRJ",
};

if (!getApps().length) {
  initializeApp(firebaseConfig);
}

export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore();
export const storage = getStorage();