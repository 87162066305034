import React, { useState, useEffect } from 'react';
import { auth, googleProvider } from './firebase';
import { signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { LogOut, Loader2 } from 'lucide-react';
import AddDetails from './components/AddDetails';
import ShowDetails from './components/ShowDetails';
import myImage from './v insure.png';
const LoadingSpinner = () => (
  <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
    <Loader2 className="w-8 h-8 text-blue-500 animate-spin mb-2" />
    <div className="text-lg text-gray-600">Loading...</div>
  </div>
);

const SignInPage = ({ onSignIn }) => (
  <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
    <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
      <h1 className="text-3xl font-bold text-center mb-8">Employee Management</h1>
      <div className="space-y-6">
        <div className="text-center text-gray-600 mb-6">
          Please sign in to continue
        </div>
        <button
          onClick={onSignIn}
          className="w-full flex items-center justify-center space-x-3 px-6 py-3 bg-white border-2 border-gray-200 rounded-lg hover:bg-gray-50 transition-colors duration-200"
        >
          <img 
            src="https://cdnjs.cloudflare.com/ajax/libs/simple-icons/11.3.0/google.svg" 
            alt="Google"
            className="w-5 h-5"
          />
          <span className="text-gray-700 font-medium">Continue with Google</span>
        </button>
      </div>
    </div>
  </div>
);

const Header = ({ user, onSignOut, activeTab, onTabChange }) => (
  <div className="bg-white shadow-md">
    <header className="max-w-4xl mx-auto p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-4">
          <div className=" p-2 rounded-lg  ">
          {/* <img src={myImage} alt="Description" /> */}
          <h1>Alr Group</h1>
           
          </div>
        </div>
        <div className="flex items-center space-x-6">
          <div className="flex items-center space-x-3">
            <img
              src={user.photoURL || "/api/placeholder/32/32"}
              alt={user.displayName}
              className="w-8 h-8 rounded-full border-2 border-gray-200"
              onError={(e) => {
                e.target.src = "/api/placeholder/32/32";
              }}
            />
            <span className="text-gray-700 font-medium hidden sm:inline">
              {user.displayName}
            </span>
          </div>
          <button
            onClick={onSignOut}
            className="flex items-center space-x-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200"
          >
            <LogOut className="w-4 h-4" />
            <span className="hidden sm:inline">Sign Out</span>
          </button>
        </div>
      </div>
      
      <nav className="flex space-x-1">
        <button
          onClick={() => onTabChange('add')}
          className={`px-4 py-2 rounded-t-lg transition-colors duration-200 ${
            activeTab === 'add'
              ? 'bg-blue-500 text-white'
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          Add Leads
        </button>
        <button
          onClick={() => onTabChange('show')}
          className={`px-4 py-2 rounded-t-lg transition-colors duration-200 ${
            activeTab === 'show'
              ? 'bg-blue-500 text-white'
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          Show Leads
        </button>
      </nav>
    </header>
  </div>
);

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('add');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, 
      (user) => {
        setUser(user);
        setLoading(false);
        setError(null);
      },
      (error) => {
        console.error('Auth state change error:', error);
        setError(error.message);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Failed to sign in with Google. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      setLoading(true);
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Failed to sign out. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  if (!user) {
    return <SignInPage onSignIn={handleGoogleSignIn} />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header 
        user={user} 
        onSignOut={handleSignOut} 
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
      
      <main className="max-w-4xl mx-auto p-4 space-y-6">
        <div className="space-y-6">
          {activeTab === 'add' ? (
            <AddDetails currentUser={user} />
          ) : (
            <ShowDetails currentUser={user} />
          )}
        </div>
      </main>
    </div>
  );
};

export default App;




