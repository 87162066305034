import React, { useState, useEffect } from 'react';
import { 
  MapPin, Phone, Store, FileText, Calendar, Camera, User, Clock,
  X, AlertCircle, Loader2
} from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || 'https://tarvelbuddychatbot.lm.r.appspot.com';

const ShowDetails = ({ currentUser }) => {
  const [visits, setVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [imageLoadErrors, setImageLoadErrors] = useState({});

  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const response = await fetch(`${API_URL}/api/visits?createdBy=${currentUser.email}`);
        if (!response.ok) throw new Error(`HTTP error ${response.status}`);
        const data = await response.json();
        const visitsWithPhotos = data.data.map(visit => ({
          ...visit,
          photoUrl: visit.photoUrl ? `${API_URL}${visit.photoUrl}` : null
        }));
        setVisits(visitsWithPhotos);
      } catch (error) {
        console.error('Error fetching visits:', error);
        setError('Failed to load visit details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchVisits();
  }, [API_URL, currentUser.email]);

  const handleViewDetails = (visit) => {
    setSelectedVisit(visit);
  };

  const handleCloseDetails = () => {
    setSelectedVisit(null);
  };

  const handleImageError = (visitId) => {
    setImageLoadErrors(prev => ({ ...prev, [visitId]: true }));
  };

  if (error) {
    return (
      <div className="p-4 rounded-lg bg-red-50 border border-red-200 flex items-center space-x-2">
        <AlertCircle className="h-5 w-5 text-red-600" />
        <p className="text-red-700">{error}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="space-y-4">
        <div className="grid grid-cols-1 gap-3">
          {[1, 2, 3].map(i => (
            <div key={i} className="bg-gray-100 rounded-lg p-4">
              <div className="h-5 bg-gray-200 rounded w-1/2 mb-2 animate-pulse"></div>
              <div className="h-4 bg-gray-200 rounded w-1/3 animate-pulse"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4 px-2 sm:px-4">
      {/* Visit List - Single Column on Mobile */}
      <div className="grid grid-cols-1 gap-4">
        {visits.length === 0 ? (
          <div className="text-center py-8 text-gray-500 bg-white rounded-lg">
            No visit details found
          </div>
        ) : (
          visits.map((visit) => (
            <div 
              key={visit._id} 
              className="border rounded-lg p-4 bg-white shadow-sm hover:shadow-md transition-shadow"
              onClick={() => handleViewDetails(visit)}
            >
              <div className="flex gap-4">
                {/* Visit Image */}
                <div className="relative shrink-0">
                  {!visit.photoUrl || imageLoadErrors[visit._id] ? (
                    <div className="w-16 h-16 sm:w-20 sm:h-20 bg-gray-100 rounded-lg flex items-center justify-center">
                      <User className="w-8 h-8 text-gray-400" />
                    </div>
                  ) : (
                    <img 
                      src={visit.photoUrl}
                      alt={visit.name}
                      onError={() => handleImageError(visit._id)}
                      className="w-16 h-16 sm:w-20 sm:h-20 object-cover rounded-lg"
                    />
                  )}
                </div>

                {/* Visit Details */}
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="font-bold text-lg truncate">{visit.name}</h3>
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      visit.status === 'COMPLETED' 
                        ? 'bg-green-100 text-green-800' 
                        : visit.status === 'FOLLOW REQUIRED'
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-gray-100 text-gray-800'
                    }`}>
                      {visit.status}
                    </span>
                  </div>
                  
                  <div className="space-y-1 text-sm text-gray-600">
                    <div className="flex items-center gap-2">
                      <Phone className="w-4 h-4 text-blue-500 shrink-0" />
                      <span className="truncate">{visit.phone}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Store className="w-4 h-4 text-green-500 shrink-0" />
                      <span className="truncate">{visit.shopAddress}</span>
                    </div>
                    {visit.location && (
                      <div className="flex items-center gap-2">
                        <MapPin className="w-4 h-4 text-red-500 shrink-0" />
                        <span className="truncate">{visit.location.address}</span>
                      </div>
                    )}
                    {visit.remarks && (
                      <div className="flex items-center gap-2">
                        <FileText className="w-4 h-4 text-purple-500 shrink-0" />
                        <span className="truncate">{visit.remarks}</span>
                      </div>
                    )}
                    <div className="flex items-center gap-2">
                      <Calendar className="w-4 h-4 text-orange-500 shrink-0" />
                      <span className="truncate">
                        {new Date(visit.createdAt).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Visit Details Modal */}
      {selectedVisit && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-2"
          onClick={handleCloseDetails}
        >
          <div className="relative bg-white rounded-lg shadow-lg w-full max-w-4xl p-6 space-y-4">
            <button 
              className="absolute top-2 right-2 bg-black bg-opacity-50 text-white rounded-full p-2"
              onClick={handleCloseDetails}
            >
              <X className="w-6 h-6" />
            </button>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold">{selectedVisit.name}</h2>
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                  selectedVisit.status === 'COMPLETED' 
                    ? 'bg-green-100 text-green-800' 
                    : selectedVisit.status === 'FOLLOW REQUIRED'
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-gray-100 text-gray-800'
                }`}>
                  {selectedVisit.status}
                </span>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Phone className="w-5 h-5 text-blue-500 shrink-0" />
                    <span>{selectedVisit.phone}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Store className="w-5 h-5 text-green-500 shrink-0" />
                    <span>{selectedVisit.shopAddress}</span>
                  </div>
                  {selectedVisit.location && (
                    <div className="flex items-center gap-2">
                      <MapPin className="w-5 h-5 text-red-500 shrink-0" />
                      <span>{selectedVisit.location.address}</span>
                    </div>
                  )}
                  {selectedVisit.remarks && (
                    <div className="flex items-center gap-2">
                      <FileText className="w-5 h-5 text-purple-500 shrink-0" />
                      <span>{selectedVisit.remarks}</span>
                    </div>
                  )}
                  <div className="flex items-center gap-2">
                    <Calendar className="w-5 h-5 text-orange-500 shrink-0" />
                    <span>
                      {new Date(selectedVisit.createdAt).toLocaleString()}
                    </span>
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <FileText className="w-5 h-5 text-purple-500 shrink-0" />
                    <span>{selectedVisit.purposeOfVisit}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Clock className="w-5 h-5 text-gray-500 shrink-0" />
                    <span>{selectedVisit.visitDuration} minutes</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <User className="w-5 h-5 text-gray-500 shrink-0" />
                    <span>{selectedVisit.decisionMaker}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Camera className="w-5 h-5 text-gray-500 shrink-0" />
                    <span>{selectedVisit.productsDiscussed}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Calendar className="w-5 h-5 text-orange-500 shrink-0" />
                    <span>
                      {new Date(selectedVisit.followUpDate).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <h3 className="text-xl font-semibold">Requirements</h3>
                <p>{selectedVisit.requirements}</p>
              </div>

              <div className="space-y-2">
                <h3 className="text-xl font-semibold">Meeting Outcomes</h3>
                <div className="space-y-1">
                  <p><strong>Outcome:</strong> {selectedVisit.outcome}</p>
                  <p><strong>Next Steps:</strong> {selectedVisit.nextSteps}</p>
                  <p><strong>Competitors:</strong> {selectedVisit.competitors}</p>
                  <p><strong>Probability:</strong> {selectedVisit.probability}%</p>
                  <p><strong>Budget:</strong> ₹{selectedVisit.budget}</p>
                  <p><strong>Potential Revenue:</strong> ₹{selectedVisit.potentialRevenue}</p>
                </div>
              </div>

              <div className="space-y-2">
                <h3 className="text-xl font-semibold">Follow-up Details</h3>
                <div className="space-y-1">
                  <p><strong>Follow-up Date:</strong> {new Date(selectedVisit.followUpDate).toLocaleDateString()}</p>
                  <p><strong>Follow-up Notes:</strong> {selectedVisit.followUpNeeded}</p>
                </div>
              </div>

              <div className="space-y-2">
                <h3 className="text-xl font-semibold">Additional Details</h3>
                <div className="space-y-1">
                  <p><strong>Meeting Notes:</strong> {selectedVisit.meetingNotes}</p>
                  <p><strong>Customer Feedback:</strong> {selectedVisit.customerFeedback}</p>
                  <p><strong>Pain Points:</strong> {selectedVisit.painPoints}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowDetails;